import React from "react";

export const questions = {
  "rådgiver": [
    {
      "question": "Hvilket glas er fyldt med det gode vand?",
      "answers": [{
        "text": "A",
        "image": require("/src/images/Vandglas.png")
      }, {
        "text": "B",
        "image": require("/src/images/Vandglas.png")
      }],
      "correctAnswer": "B",
      "explainer":
      {
        "correct":
        {
          "headline": "Flot. Du er heldig og har ret.",
          "body": "Heldig, fordi du jo ikke kan vide med sikkerhed at vandinstallationen er 100% i orden. Derfor kan du heller ikke altid se, om vandet er det. Men her har du ret. "
        },
        "wrong": {
          "headline": "Plask. Det rigtige svar var B",
          "body": "Men det ér svært. For hvis du ikke ved, om vandinstallationen er 100% i orden, så kan du heller ikke vide om vandet er det."
        }
      }

    },
    {
      "question": "Kan det være farligt at tage brusebad?",
      "answers": [{
        "text": "Ja",
        "image": require("/src/images/Bruser.png")
      }, {
        "text": "Nej",
        "image": require("/src/images/Bruser.png")
      }],
      "correctAnswer": "Ja",
      "explainer":
      {
        "correct":
        {
          "headline": "Korrekt. Det kan godt være farligt",
          "body": "Hvert år bliver 300 danskere alvorligt syge, fordi de får legionella i brusebadet. Synderen er vandsystemer, som ikke er konstrueret, vedligeholdt eller driftet korrekt. Hvis temperaturen på det varme vand i installationen afviger fra specifikationerne, kan det desværre have fatale konsekvenser. "
        },
        "wrong": {
          "headline": "Forkert. Det kan godt være farligt.",
          "body": "Hvert år bliver 300 danskere alvorligt syge, fordi de får legionella i brusebadet. Synderen er vandsystemer, som ikke er konstrueret, vedligeholdt eller driftet korrekt. Hvis temperaturen på det varme vand i installationen afviger fra specifikationerne, kan det desværre have fatale konsekvenser."
        }
      }

    },
    {
      "question": "Er vandet fra vandhanen bedre end vandet fra flasken?",
      "answers": [{
        "text": "Ja",
        "image": require("/src/images/VandFraHane.png")
      }, {
        "text": "Nej",
        "image": require("/src/images/VandFraFlaske.png")
      }],
      "correctAnswer": "Ja",
      "explainer":
      {
        "correct":
        {
          "headline": "Korrekt. Vand fra hanen er bedst.",
          "body": "Hvis der ikke er noget galt med din installation, så kan du godt stole på kvaliteten af det vand, som du tapper fra vandhanen. I Danmark bliver der endda stillet højere krav til kvaliteten af drikkevand fra hanen end det, du kan købe på flaske."
        },
        "wrong": {
          "headline": "Forkert. Vandet fra hanen er bedst",
          "body": "Hvis der ikke er noget galt med din installation, så kan du godt stole på kvaliteten af det vand, som du tapper fra vandhanen. I Danmark bliver der endda stillet højere krav til kvaliteten af drikkevand fra hanen end det, du kan købe på flaske."
        }
      }

    },
    {
      "question": "Kan du regne med, at vandet i nr. 10 er lige så godt som længere nede ad gaden i nr. 16? ",
      "answers": [{
        "text": "Ja",
        "image": require("/src/images/Vandhane-kopi.png")
      }, {
        "text": "Nej",
        "image": require("/src/images/Vandhane.png")
      }],
      "correctAnswer": "Nej",
      "explainer":
      {
        "correct":
        {
          "headline": "Korrekt. Det kan du ikke regne med.",
          "body": "Vandet fra vandværket er det samme. Men derfra kan der være store forskelle på ejendommen og de rørledninger, som vandet løber i. Og det betyder meget for drikkevandskvaliteten."
        },
        "wrong": {
          "headline": "Forkert. Det kan du ikke regne med.",
          "body": "Vandet fra vandværket er det samme. Men derfra kan der være store forskelle på ejendommen og de rørledninger, som vandet løber i. Og det betyder meget for drikkevandskvaliteten."
        }
      }

    },
    {
      "question": "Hvem vil du helst spørge til råds om det gode vand?",
      "answers": [{
        "text": "A",
        "image": require("/src/images/logo_w_border_new.svg")
      }, {
        "text": "B",
        "image": require("/src/images/viggo_new.svg")
      }],
      "correctAnswer": "A",
      "explainer":
      {
        "correct":
        {
          "headline": "Et godt valg! Det gode vand fortjener god rådgivning.",
          "body": "Vores kernekompetence er at bevare vandkvaliteten. Det afspejles i valg af materialer og design af alle produkter. Men vi har også seminarer og deler gerne vores viden, hvis du har spørgsmål om, hvordan du sikrer det gode vand. "
        },
        "wrong": {
          "headline": "Desværre. Viggo er sikkert en flink fyr, men...",
          "body": "Hvis du vil være sikker på det gode vand, så skal du tale med os. Vores kernekompetence er at bevare vandkvaliteten. Det afspejles i valg af materialer og design af alle produkter. Men vi har også seminarer og deler gerne vores viden, hvis du har spørgsmål om, hvordan du sikrer det gode vand."
        }
      }
    }
  ],

  "installatør": [
      {
          "question": "Hvilket glas er fyldt med det gode vand?",
          "answers": [{
              "text": "A",
              "image": require("/src/images/Vandglas.png")
          }, {
              "text": "B",
              "image": require("/src/images/Vandglas.png")
          }],
          "correctAnswer": "B",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Flot. Du er heldig og har ret.",
                          "body": "Heldig, fordi du jo ikke kan vide med sikkerhed at vandinstallationen er 100% i orden. Derfor kan du heller ikke altid se, om vandet er det. Men her har du ret. "
                      },
                  "wrong": {
                      "headline": "Plask. Det rigtige svar var B",
                      "body": "Men det ér svært. For hvis du ikke ved, om vandinstallationen er 100% i orden, så kan du heller ikke vide om vandet er det."
                  }
              }

      },
      {
          "question": "Kan det være farligt at tage brusebad?",
          "answers": [{
              "text": "Ja",
              "image": require("/src/images/Bruser.png")
          }, {
              "text": "Nej",
              "image": require("/src/images/Bruser.png")
          }],
          "correctAnswer": "Ja",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Korrekt. Det kan godt være farligt",
                          "body": "Hvert år bliver 300 danskere alvorligt syge, fordi de får legionella i brusebadet. Synderen er vandsystemer, som ikke er konstrueret, vedligeholdt eller driftet korrekt. Hvis temperaturen på det varme vand i installationen afviger fra specifikationerne, kan det desværre have fatale konsekvenser. "
                      },
                  "wrong": {
                      "headline": "Forkert. Det kan godt være farligt.",
                      "body": "Hvert år bliver 300 danskere alvorligt syge, fordi de får legionella i brusebadet. Synderen er vandsystemer, som ikke er konstrueret, vedligeholdt eller driftet korrekt. Hvis temperaturen på det varme vand i installationen afviger fra specifikationerne, kan det desværre have fatale konsekvenser."
                  }
              }

      },
      {
          "question": "Er vandet fra vandhanen bedre end vandet fra flasken?",
          "answers": [{
              "text": "Ja",
              "image": require("/src/images/VandFraHane.png")
          }, {
              "text": "Nej",
              "image": require("/src/images/VandFraFlaske.png")
          }],
          "correctAnswer": "Ja",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Korrekt. Vand fra hanen er bedst.",
                          "body": "Hvis der ikke er noget galt med din installation, så kan du godt stole på kvaliteten af det vand, som du tapper fra vandhanen. I Danmark bliver der endda stillet højere krav til kvaliteten af drikkevand fra hanen end det, du kan købe på flaske."
                      },
                  "wrong": {
                      "headline": "Forkert. Vandet fra hanen er bedst",
                      "body": "Hvis der ikke er noget galt med din installation, så kan du godt stole på kvaliteten af det vand, som du tapper fra vandhanen. I Danmark bliver der endda stillet højere krav til kvaliteten af drikkevand fra hanen end det, du kan købe på flaske."
                  }
              }

      },
      {
          "question": "Kan du regne med, at vandet i nr. 10 er lige så godt som længere nede ad gaden i nr. 16? ",
          "answers": [{
              "text": "Ja",
              "image": require("/src/images/Vandhane-kopi.png")
          }, {
              "text": "Nej",
              "image": require("/src/images/Vandhane.png")
          }],
          "correctAnswer": "Nej",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Korrekt. Det kan du ikke regne med.",
                          "body": "Vandet fra vandværket er det samme. Men derfra kan der være store forskelle på ejendommen og de rørledninger, som vandet løber i. Og det betyder meget for drikkevandskvaliteten."
                      },
                  "wrong": {
                      "headline": "Forkert. Det kan du ikke regne med.",
                      "body": "Vandet fra vandværket er det samme. Men derfra kan der være store forskelle på ejendommen og de rørledninger, som vandet løber i. Og det betyder meget for drikkevandskvaliteten."
                  }
              }

      },
      {
          "question": "Hvem vil du helst spørge til råds om det gode vand?",
          "answers": [{
              "text": "A",
              "image": require("/src/images/logo_w_border_new.svg")
          }, {
              "text": "B",
              "image": require("/src/images/viggo_new.svg")
          }],
          "correctAnswer": "A",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Et godt valg! Det gode vand fortjener god rådgivning.",
                          "body": "Vores kernekompetence er at bevare vandkvaliteten. Det afspejles i valg af materialer og design af alle produkter. Men vi har også seminarer og deler gerne vores viden, hvis du har spørgsmål om, hvordan du sikrer det gode vand. "
                      },
                  "wrong": {
                      "headline": "Desværre. Viggo er sikkert en flink fyr, men...",
                      "body": "Hvis du vil være sikker på det gode vand, så skal du tale med os. Vores kernekompetence er at bevare vandkvaliteten. Det afspejles i valg af materialer og design af alle produkter. Men vi har også seminarer og deler gerne vores viden, hvis du har spørgsmål om, hvordan du sikrer det gode vand."
                  }
              }
      }
  ],

  "boligforening": [
      {
          "question": "Hvilket glas er fyldt med det gode vand?",
          "answers": [{
              "text": "A",
              "image": require("/src/images/Vandglas.png")
          }, {
              "text": "B",
              "image": require("/src/images/Vandglas.png")
          }],
          "correctAnswer": "B",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Flot. Du er heldig og har ret.",
                          "body": "Heldig, fordi du jo ikke kan vide med sikkerhed at vandinstallationen er 100% i orden. Derfor kan du heller ikke altid se, om vandet er det. Men her har du ret. "
                      },
                  "wrong": {
                      "headline": "Plask. Det rigtige svar var B",
                      "body": "Men det ér svært. For hvis du ikke ved, om vandinstallationen er 100% i orden, så kan du heller ikke vide om vandet er det."
                  }
              }

      },
      {
          "question": "Kan det være farligt at tage brusebad?",
          "answers": [{
              "text": "Ja",
              "image": require("/src/images/Bruser.png")
          }, {
              "text": "Nej",
              "image": require("/src/images/Bruser.png")
          }],
          "correctAnswer": "Ja",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Korrekt. Det kan godt være farligt",
                          "body": "Hvert år bliver 300 danskere alvorligt syge, fordi de får legionella i brusebadet. Synderen er vandsystemer, som ikke er konstrueret, vedligeholdt eller driftet korrekt. Hvis temperaturen på det varme vand i installationen afviger fra specifikationerne, kan det desværre have fatale konsekvenser. "
                      },
                  "wrong": {
                      "headline": "Forkert. Det kan godt være farligt.",
                      "body": "Hvert år bliver 300 danskere alvorligt syge, fordi de får legionella i brusebadet. Synderen er vandsystemer, som ikke er konstrueret, vedligeholdt eller driftet korrekt. Hvis temperaturen på det varme vand i installationen afviger fra specifikationerne, kan det desværre have fatale konsekvenser."
                  }
              }

      },
      {
          "question": "Er vandet fra vandhanen bedre end vandet fra flasken?",
          "answers": [{
              "text": "Ja",
              "image": require("/src/images/VandFraHane.png")
          }, {
              "text": "Nej",
              "image": require("/src/images/VandFraFlaske.png")
          }],
          "correctAnswer": "Ja",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Korrekt. Vand fra hanen er bedst.",
                          "body": "Hvis der ikke er noget galt med din installation, så kan du godt stole på kvaliteten af det vand, som du tapper fra vandhanen. I Danmark bliver der endda stillet højere krav til kvaliteten af drikkevand fra hanen end det, du kan købe på flaske."
                      },
                  "wrong": {
                      "headline": "Forkert. Vandet fra hanen er bedst",
                      "body": "Hvis der ikke er noget galt med din installation, så kan du godt stole på kvaliteten af det vand, som du tapper fra vandhanen. I Danmark bliver der endda stillet højere krav til kvaliteten af drikkevand fra hanen end det, du kan købe på flaske."
                  }
              }

      },
      {
          "question": "Kan du regne med, at vandet i nr. 10 er lige så godt som længere nede ad gaden i nr. 16? ",
          "answers": [{
              "text": "Ja",
              "image": require("/src/images/Vandhane-kopi.png")
          }, {
              "text": "Nej",
              "image": require("/src/images/Vandhane.png")
          }],
          "correctAnswer": "Nej",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Korrekt. Det kan du ikke regne med.",
                          "body": "Vandet fra vandværket er det samme. Men derfra kan der være store forskelle på ejendommen og de rørledninger, som vandet løber i. Og det betyder meget for drikkevandskvaliteten."
                      },
                  "wrong": {
                      "headline": "Forkert. Det kan du ikke regne med.",
                      "body": "Vandet fra vandværket er det samme. Men derfra kan der være store forskelle på ejendommen og de rørledninger, som vandet løber i. Og det betyder meget for drikkevandskvaliteten."
                  }
              }

      },
      {
          "question": "Hvem vil du helst spørge til råds om det gode vand?",
          "answers": [{
              "text": "A",
              "image": require("/src/images/logo_w_border_new.svg")
          }, {
              "text": "B",
              "image": require("/src/images/viggo_new.svg")
          }],
          "correctAnswer": "A",
          "explainer":
              {
                  "correct":
                      {
                          "headline": "Et godt valg! Det gode vand fortjener god rådgivning.",
                          "body": "Vores kernekompetence er at bevare vandkvaliteten. Det afspejles i valg af materialer og design af alle produkter. Men vi har også seminarer og deler gerne vores viden, hvis du har spørgsmål om, hvordan du sikrer det gode vand. "
                      },
                  "wrong": {
                      "headline": "Desværre. Viggo er sikkert en flink fyr, men...",
                      "body": "Hvis du vil være sikker på det gode vand, så skal du tale med os. Vores kernekompetence er at bevare vandkvaliteten. Det afspejles i valg af materialer og design af alle produkter. Men vi har også seminarer og deler gerne vores viden, hvis du har spørgsmål om, hvordan du sikrer det gode vand."
                  }
              }
      }
  ]
}

function QuizHeader(props) {
    return (
        <div>
            <p className="uppercase font-black text-center mt-4">{`Spørgsmål ` + props.current}</p>
            <h1 className="text-2xl font-bold text-center  whitespace-pre-line align-bottom">
                {props.children}
            </h1>
        </div>
    );
}

export default QuizHeader;

function Home(props) {
    const onClick = props.onClick;
    const handleChange = props.handleChange;
    return (
        <div>
            {/*<Header>Choose a category to get started:</Header>*/}
            <div className="grid grid-rows-1 grid-cols-1 md:grid-cols-7 gap-4 md:mx-0 lg:mx-20 md:my-24 justify-center mt-2 overflow-hidden">
                <div className="col-span-1 md:col-span-3 border border-yellow border-2 p-3 md:p-10 bg-white flex justify-between items-center">
                    <div className="min-w-fit">
                        <h1 className="uppercase text-5xl leading-1 block align-center mx-auto my-auto box-decoration-clone">
                            <span className="font-black">Quiz & <br/> vand.</span><br/>
                            Hvem <br/> er du?
                        </h1>

                    </div>
                    <div className="">
                        <img src={require('/src/images/prices.png')} alt="Præmier" className="md:hidden object-scale-down h-64"/>
                    </div>
                </div>

                <div className="md:col-span-4 bg-black p-10 flex flex-col justify-around text-white justify-between border border-yellow border-2">
                    <div className="space-y-2">
                        <label className="text-lg block">
                        <input
                            type="radio"
                            name="answer"
                            value="rådgiver"
                            className="bg-gray-800 checked:bg-yellow active:bg-yellow text-yellow mr-3" onChange={handleChange}
                        />
                            Jeg er <span className="font-bold">rådgiver</span>
                        </label>

                        <label className="text-lg block">
                        <input
                            type="radio"
                            name="answer"
                            value="installatør"
                            className="bg-gray-800 checked:bg-yellow active:bg-yellow text-yellow mr-3" onChange={handleChange}
                        />
                            Jeg er <span className="font-bold">vvs-installatør</span>
                        </label>

                        <label className="text-lg block">
                        <input
                            type="radio"
                            name="answer"
                            value="boligforening"
                            className="bg-gray-800 checked:bg-yellow active:bg-yellow text-yellow mr-3" onChange={handleChange}
                        />
                            Jeg er fra en <span className="font-bold">boligforening</span>
                        </label>
                    </div>

                    <div className="mt-10">
                        <button className="bg-yellow px-10 py-3 uppercase align-bottom text-black"
                        onClick={onClick}>Start →</button>
                    </div>
                </div>
            <img src={require('/src/images/prices.png')} alt="Præmier" className="hidden md:block md:absolute  md:-right-32 lg:-right-6 top-[20%] h-96 object-scale-down overflow-hidden"/>
            </div>
            {/*<Modal />*/}
        </div>
    );
}

export default Home;

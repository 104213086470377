import React from "react";

function Modal() {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <>
            <a
                className="text-gray-400 text-sm"
                href="#"
                onClick={() => setShowModal(true)}
            >Konkurrencebetingelser.</a>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Konkurrencebetingelser:
                                    </p>
                                    <p>
                                        For at deltage i konkurrencen skal deltageren være mindst 18 år gammel. <br/> Hver person må kun deltage en gang. Bliver disse punkter brudt, forbeholder Viega sig retten til at frasortere personen fra konkurrencen.<br/>  Konkurrencen er gratis at deltage i, og der er ikke nogen købspligt. Alle konkurrencevindere kontaktes direkte. Viega forbeholder sig retten til at offentliggøre navn og by på vinderne af konkurrencen på både vores Facebook-side og andre medier, som Viega anvender. <br/> Konkurrencedeltagerne accepterer ved sin deltagelse i konkurrencen, at Viega må eksponere navn og by på vores off/online-platforme. <br/> Der trækkes alm. lod blandt deltagerne, og der udloddes 3 identiske præmier, Aarke Carbonater 3 + en vandflaske med Viega logo. Hver præmie har en værdi på ca. 1.250 kr.<br/>  Præmien kan ikke ombyttes til kontanter. <br/> Der trækkes lod blandt alle deltager 15/1 2022. Vinderen får direkte besked. <br/> Viega kontakter vinderen via e-mail. Der skal svares 4 uger fra at vinderen har modtaget oplysning om at have vundet konkurrencen.<br/>  Svares der ikke inden fristens udløb mister vinderne retten til præmien og en ny vinder udtrækkes/kåres. Præmien sendes til vinderen til den aftalte adressse med PostNord. Det er ikke Viegas ansvar at præmien kommer frem. Ved deltagelse i en konkurrence hos Viega er deltageren indforstået med ovenstående konkurrencebetingelser. <br/> Deltagerne kan ikke efterfølgende rette krav mod Viega.
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Luk
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}

export default Modal;
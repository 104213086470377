import React from "react";
import EndForm from "./EndForm";
import Thanks from "./Thanks";

class EndQuiz extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
        }
        this.submitState = this.submitState.bind(this);
    }
    
    submitState(e) {
        this.setState({ submitted: true });
        setTimeout(() => {
            window.location.href = "https://www.viega.dk/da/service/viegaviden/det-gode-vand.html";
        }, 15000);
    }

    render() {
        let element;
        if (!this.state.submitted) {
            element  = <EndForm
                numCorrect={this.props.numCorrect}
                subject={this.props.subject}
                utmTags={this.props.utmTags}
                submitState={this.submitState} />;
        } else {
            element = <Thanks />
        }
        return (
            <>
                {element}
            </>
        );
    }
}

export default EndQuiz;

function Answer(props) {
    return (
        <div
            className="rounded-md col-span-1 flex flex-col"
            onClick={() => props.onClick(props.answer)}
        >
            <img src={props.image} className="h-52" alt="Quiz image"/>

            {!props.statusShown && (
                <button
                    value={props.answer}
                    name="answer"
                    className="my-5 bg-yellow text-black px-5 py-3 uppercase mx-auto disabled:border-2 disabled:border-yellow disabled:bg-white"
                    disabled={props.statusShown}
                >
                    {!props.statusShown &&
                        props.answer
                    }</button>
            )}
        </div>
    );
}

function AnswerCard(props) {
    return (
        <div className=" w-full">
            <div className="pt-6 pb-2 justify-center justify-evenly flex">
                {props.answers.answers.map(answer => (
                    <Answer
                        key={answer.text}
                        answer={answer.text}
                        image={`${answer.image}`}
                        onClick={props.onClick}
                        statusShown={props.statusShown}
                    />
                ))}
            </div>
        </div>
    );
}

export default AnswerCard;

import Modal from "../../components/Modal";

function Footer() {
    return (
        <footer className="bg-black w-full mt-24 md:mt-0 md:bottom-auto md:static md:w-auto">
            <nav className="shadow-md py-2 bg-black relative flex items-center w-full justify-between">
                <div className="px-6 w-full flex flex-wrap items-center justify-between">
                    <div className="flex flex-col md:flex-row items-center h-20 w-full justify-between">
                        <div>
                            <p className="text-white font-black md:hidden">
                                Viega. Viden om vand.
                            </p>
                        </div>
                       <div>
                           <Modal/>
                       </div>
                    </div>
                </div>
            </nav>
        </footer>
    )
}

export default Footer;
import { useState } from "react";

function EndForm(props) {
    const [inputs, setInputs] = useState({name: '', lastname: '', telephone: '', company: '', permission: '', email: ''});
    const submitState = props.submitState;


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))

        // Reset error message
        // console.log(name)
        // console.log(event.target)
        // console.log(event.target.value)
        // console.log(event.target.value !== '')
        
        if(name === 'permission') {
            if(document.getElementsByName("permission")[0].checked === true) {
                event.target.nextElementSibling.nextElementSibling.classList.add('hidden')
            } else {
                event.target.nextElementSibling.nextElementSibling.classList.remove('hidden')
            }
        } else {
            if(value !== '') {
                event.target.nextElementSibling.classList.add('hidden')
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        // Show validation error
        if(inputs['name'].length === 0) {
            document.getElementsByName("name")[0].nextElementSibling.classList.remove('hidden')
        }
        if(inputs['lastname'].length === 0) {
            document.getElementsByName("lastname")[0].nextElementSibling.classList.remove('hidden')
        }
        if(inputs['telephone'].length === 0) {
            document.getElementsByName("telephone")[0].nextElementSibling.classList.remove('hidden')
        }
        if(inputs['company'].length === 0) {
            document.getElementsByName("company")[0].nextElementSibling.classList.remove('hidden')
        }
        if(inputs['permission'].length === 0) {
            document.getElementsByName("permission")[0].nextElementSibling.nextElementSibling.classList.remove('hidden')
        }
        if(inputs['email'].length === 0) {
            document.getElementsByName("email")[0].nextElementSibling.classList.remove('hidden')
        }

        // Simple validation:
        if(inputs['name'].length !== 0
            && inputs['lastname'].length !== 0
            && inputs['telephone'].length !== 0
            && inputs['company'].length !== 0
            && inputs['permission'].length !== 0
            && inputs['email'].length !== 0
        ) {

            // console.log('Submitted! yeah')

            const formData = {
                "client": "viega",
                "campaign": "quiz",
                "email": inputs['email'],
                "data": {...inputs, ...props}
            }

            fetch('https://campaigns.kogp.dk/api/campaigns/store', {
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
                // body: JSON.stringify(inputs)
            }).then((response) => {

                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (response.data && response.data.message) || response.status;
                    return Promise.reject(error);
                }

                // console.log(response);
                submitState();
                // return response.json()
            })
        }
    }

    return (
        <div className="flex justify-center mb-32 flex-col md:mt-32 md:border-2 md:border-yellow md:bg-white md:p-10 md:mb-10">
            <div className="bg-white border-2 border-yellow md:border-none p-3 md:mt-0 md:pt-0 my-4 flex">
                <div className="basis-2/3 md:basis-full">
                    <h1 className="text-2xl font-black">
                        Udfyld felterne. <br className="md:hidden" />
                        Så er du med i lodtrækningen.
                    </h1>
                </div>
                <div className="basis-1/3 md:hidden">
                    <img src={require('/src/images/prices.png')} alt="Præmier"
                         className=" object-scale-down" />
                </div>
            </div>

            <form className="grid grid-cols-1 md:grid-cols-2 gap-6 md:w-9/12" onSubmit={handleSubmit}>
                <div className="flex flex-col">
                    <input
                      type="text"
                      placeholder="Navn"
                      name="name"
                      onChange={handleChange}
                    />
                    <span className="error text-red-700 hidden">Du mangler at skrive et navn</span>
                </div>
                <div className="flex flex-col">
                    <input
                      type="text"
                      placeholder="Efternavn"
                      name="lastname"
                      onChange={handleChange}
                    />
                    <span className="error text-red-700 hidden">Du mangler at skrive et efternavn</span>
                </div>
                <div className="flex flex-col">
                    <input
                      type="tel"
                      placeholder="Telefonnr."
                      name="telephone"
                      onChange={handleChange}
                    />
                    <span className="error text-red-700 hidden">Du mangler at skrive et telefonnummer</span>
                </div>
                <div className="flex flex-col">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                    />
                    <span className="error text-red-700 hidden">Du mangler at skrive en email-adresse</span>
                </div>
                <div className="flex flex-col">
                    <input
                      type="text"
                      placeholder="Firmanavn"
                      name="company"
                      onChange={handleChange}
                    />
                    <span className="error text-red-700 hidden">Du mangler at skrive et firma</span>
                </div>
                <div className="flex md:col-span-2">
                    <label>
                        <input
                          type="checkbox"
                          name="permission"
                          className="float-left text-yellow"
                          onChange={handleChange}
                        />
                        <span className="ml-8 block text-sm">
                            Ja tak til at Viega må kontakte mig på e-mail med nyheder, gode råd og inspiration. Jeg kan altid let framelde mig igen.

                            Du kan læse mere om vores behandling af dine personoplysninger <a target="_blank" className="text-gray-700 hover:text-yellow visited:text-gray-700 " href="https://www.viega.dk/da/meta/footer/databeskyttelse.html">her</a>.
                        </span>
                        <span className="error text-red-700 hidden">Du mangler at give samtykke</span>
                    </label>
                </div>
                <div className="flex ">
                    <button onClick={ handleSubmit }
                            className="bg-yellow px-10 py-3 uppercase align-bottom text-black">Deltag →
                    </button>
                </div>
                <img src={require('/src/images/prices.png')} alt="Præmier"
                     className="hidden  h-96 object-scale-down overflow-hidden md:block md:absolute md:-right-14 lg:-right-6 xl:-right-0" />
            </form>
        </div>
    )
}

export default EndForm;
function Status(props) {
    if (props.correct) {
        return (
            <div className="">
                <h2 className="text-lg mt-12 font-bold text-center">
                    { props.messages.correct.headline }
                </h2>
                <p className="text-center">
                    {props.messages.correct.body}
                </p>
            </div>

        );
    }

    return (
        <div>
            <h2 className="text-lg  mt-12 font-bold text-center">
                { props.messages.wrong.headline }
            </h2>
            <p className="text-center">
                {props.messages.wrong.body}
            </p>
        </div>
    );
}

export default Status;

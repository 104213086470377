import React from "react";
import ReactDOM, { render } from "react-dom";

import Home from "./pages/Home";
import Quiz from "./pages/Quiz";
import Header from "./pages/Sections/Header";
import Footer from "./pages/Sections/Footer";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { page: "home" };

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleClick() {
        if (this.state.subject !== undefined) {
            this.setState({ page: "quiz" });
        }
    }

    handleChange(e) {
        this.setState({ subject: e.target.value });
    }

    render() {
        let page;

        if (this.state.page === "home") {
            page = <Home onClick={this.handleClick} handleChange={this.handleChange} />;
        } else {
            page = <Quiz subject={this.state.subject} />;
        }

        return (
            <>
                <Header />
                <main className="flex justify-center items-center mt-24 md:my-auto md:overflow-hidden">
                    <div className="w-full relative bg-water-with-bubbles bg-cover bg-no-repeat sm:shadow-lg p-5 w-screen sm:mx-35 flex flex-col sm:h-auto container ">
                        {page}
                    </div>
                </main>
                <Footer/>
            </>
        )
    }
}

ReactDOM.render(<App />, document.querySelector("#root"));

function Header() {
    return (
        <header>
            {/*Navbar*/}
            <nav
                className="navbar navbar-expand-lg shadow-md py-2 bg-black relative flex items-center w-full justify-between">
                <div className="px-6 w-full flex flex-wrap items-center md:justify-between justify-center">
                    <div className="flex items-center">
                        <img src={require('/src/images/logo.svg')} alt="Viega logo" className="h-16 md:h-20" />
                    </div>
                    <div className="flex items-center">
                        <span
                            className="text-white font-black hidden md:inline-block"
                        >Viega. Viden om vand.</span>
                    </div>
                </div>
            </nav>
            {/*!Navbar*/}
        </header>
    )
}

export default Header;
function ProgressBar(props) {
    return (
        <div id="progress" className="text-center space-x-2 md:absolute md:mx-5 md:my-3 right-0 top-0">

            {[...Array(props.total).keys()].map(i =>
                <span key={i} className={`inline-block w-3 h-3 box-border border border-black` + (i<=props.current ? ' bg-yellow' : ' bg-white')}></span>
            )}
        </div>
    );
}

export default ProgressBar;

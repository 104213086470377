import React from "react";

import QuizHeader from "../components/QuizHeader";
import Status from "../components/Status";
import EndQuiz from "../components/EndQuiz";
import AnswerCard from "../components/AnswerCard";
import ProgressBar from "../components/ProgressBar";

import {questions} from "../viega-questions";

class Quiz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionNumber: 0,
            numCorrect: 0,
            statusShown: false,
            currentQuestionCorrect: false,
            utmTags: {},
        };

        this.questions = questions[this.props.subject];

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.setUtm();
    }

    setUtm() {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);

        let utm = {};
        // Log the values
        params.forEach((value, key) => {
            utm = {
                [key]: value,
                ...utm
            };
        });

        this.setState({utmTags: utm});
    }

    handleClick(answer) {
        //console.log(answer);
        this.setState({ statusShown: true });

        const correctAnswer =
            this.questions[this.state.questionNumber].correctAnswer;

        answer === correctAnswer
            ? this.setStatus("correct")
            : this.setStatus("wrong");
    }

    setStatus(status) {
        if (status === "correct") {
            this.setState(state => ({
                numCorrect: state.numCorrect + 1,
                currentQuestionCorrect: true,
            }));
        } else {
            this.setState({ currentQuestionCorrect: false });
        }

    }

    switchQuestion() {
        this.setState(state => ({
            statusShown: false,
            questionNumber:
                state.questionNumber < 4 ? state.questionNumber + 1 : false,
        }));
    }

    render() {
        if (this.state.questionNumber !== false) {
            const question = this.questions[this.state.questionNumber].question;
            const answers = this.questions[this.state.questionNumber].answers;

            return (
                <div className="md:mx-0 lg:mx-20 xl:mx-32 justify-center py-14 md:h-[500px]">
                    <ProgressBar current={this.state.questionNumber} total={this.questions.length}></ProgressBar>
                    <QuizHeader current={this.state.questionNumber+1}>{question}</QuizHeader>
                    {/*<div className="flex justify-center mt-16">*/}
                    <div className="flex justify-center ">
                        <AnswerCard
                            answers={this.questions[this.state.questionNumber]}
                            onClick={this.handleClick}
                            statusShown={this.state.statusShown}
                        />
                    </div>
                    {this.state.statusShown && (
                        <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl border-2 border-yellow flex flex-col bg-white/[.98] outline-none focus:outline-none p-5 justify-items-center">
                                <Status correct={this.state.currentQuestionCorrect}
                                        messages={this.questions[this.state.questionNumber].explainer} />
                                <button
                                    onClick={() => this.switchQuestion()}
                                    className="my-5 bg-yellow text-black px-5 py-3 uppercase mx-auto"
                                >Næste →</button>
                            </div>
                        </div>
                        </>
                    )}
                </div>
            );
        }

        return <EndQuiz
            numCorrect={this.state.numCorrect}
            subject={this.props.subject}
            utmTags={this.state.utmTags}
        />;
    }
}

export default Quiz;

function Thanks()
{
    return (
        <>
            {/*<div className="flex justify-center mb-32 flex-col md:mt-32 md:border-2 md:border-yellow md:bg-white md:p-10 md:mb-10">*/}
            <div className="grid grid-cols-1 md:grid-cols-7 grid-rows-1 gap-4 mb-32 md:mt-32 border-2 border-yellow bg-white md:mb-10">
                <div className="col-span-1 md:col-span-5 p-10 ">
                    <p className="text-black text-xl uppercase">
                        {/*<span className="font-black">Tak.<br/></span>*/}
                        <span className="font-black">Tak for din deltagelse.<br/></span> Vinderen får direkte besked. Om lidt videresender vi dig til Viega.dk.<br/><br/><br/>
                        Ellers <a href="https://www.viega.dk/da/service/viegaviden/det-gode-vand.html">klik her →</a>
                        {/*Du er nu med i <br/>*/}
                        {/*lodtrækningen om <br/>*/}
                        {/*<span className="font-black">det gode vand.</span>*/}
                    </p>
                </div>
                <div className=" md:p-10  md:col-span-2 flex justify-end">
                    <img src={require('/src/images/prices.png')} alt="Præmier" className="h-96 md:absolute mx-auto md:mx-0 object-scale-down overflow-hidden top-2"/>
                </div>
            </div>
        </>
    )
}

export default Thanks;